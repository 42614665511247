"use strict";

app.common.init();
app.header.init();
app.scroll.init();
app.fancyboxExample.init();
app.slider.init();
app.enter.init();
app.questions.init();
app.map.mapLoading();
app.votePopup.init();
app.giftPopup.init();