'use strict';

/* eslint-disable no-unused-vars */
app.scroll = {
	init: function init() {
		var scrollBtn = document.getElementById('scroll-to-top');
		var firstSection = document.querySelector('.promo');
		var secondSection = document.querySelector('.rules');

		var scrollFunction = function scrollFunction() {
			if (document.documentElement.clientWidth > 991 && scrollBtn) {
				if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
					scrollBtn.style.opacity = '1';
				} else {
					scrollBtn.style.opacity = '0';
				}
			} else {
				// eslint-disable-next-line no-lonely-if
				if (scrollBtn && document.body.scrollTop > 2000 || document.documentElement.scrollTop > 2000) {
					scrollBtn.style.opacity = '1';
				} else {
					scrollBtn.style.opacity = '0';
				}
			}
		};
		var scrollToTop = function scrollToTop(e) {
			e.preventDefault();
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		};
		window.onscroll = function () {
			if (scrollBtn) {
				scrollFunction();
				scrollBtn.addEventListener('click', scrollToTop);
			}
		};

		if (document.documentElement.clientWidth >= 1280 && firstSection) {
			$(firstSection).on('mousewheel DOMMouseScroll', function (event) {
				event.preventDefault();
				var wheelDelta = event.originalEvent.wheelDelta || -event.originalEvent.detail;
				if (wheelDelta < 0) {
					secondSection.scrollIntoView({ behavior: 'smooth' });
				}
			});
		}
	}
};