'use strict';

app.header = {
	header: document.querySelector('.header'),
	init: function init() {
		this.changePosition();
		this.loginPopup();
		this.showPswdResetForm();
		this.linkScrollTo();
	},
	changePosition: function changePosition() {
		var _this = this;

		window.addEventListener('scroll', function () {
			if (app.header.header && document.documentElement.scrollTop > 80) {
				_this.header.style = 'background-color: #ffffff;';
				_this.header.classList.add('header_background');
			} else {
				_this.header.style = '';
				_this.header.classList.remove('header_background');
			}
		});
	},
	loginPopup: function loginPopup() {
		var headerLoginBtn = document.querySelector('.header__login-button');
		if (!headerLoginBtn) {
			return;
		}
		headerLoginBtn.addEventListener('click', function (event) {
			event.preventDefault();
			$.fancybox.open({
				src: './enter.html',
				type: 'ajax',
				opts: {
					smallBtn: false,
					toolbar: false,
					touch: false,
					padding: 10,
					backFocus: false
				}
			});
		});
	},
	showPswdResetForm: function showPswdResetForm() {
		$(document).ajaxComplete(function () {
			var resetBtn = document.querySelector('.reset-pswd');
			if (!resetBtn) {
				return;
			}
			var popupWrapper = document.querySelectorAll('.popup__wrapper');
			resetBtn.addEventListener('click', function (e) {
				e.preventDefault();
				popupWrapper[0].classList.add('hidden');
				popupWrapper[1].classList.remove('hidden');
			});
		});
	},
	linkScrollTo: function linkScrollTo() {
		var linkScroll = document.querySelectorAll('.link-scroll-to');

		var _loop = function _loop(i) {
			var button = linkScroll[i];
			button.addEventListener('click', function (event) {
				event.preventDefault();
				var blockID = button.getAttribute('href');
				document.querySelector(blockID).scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			});
		};

		for (var i = 0; i < linkScroll.length; i++) {
			_loop(i);
		}
	}
};