'use strict';

/* eslint-disable no-loop-func */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
app.giftPopup = {
	init: function init() {
		var giftPopupBtn = document.querySelectorAll('.gift-modal');
		var showPopup = function showPopup() {
			$.fancybox.open({
				src: './gift-popup.html',
				type: 'ajax',
				opts: {
					smallBtn: false,
					toolbar: false,
					touch: false,
					padding: 10
				}
			});
		};

		var _loop = function _loop(i) {
			var button = giftPopupBtn[i];
			button.addEventListener('click', function (event) {
				event.preventDefault();
				showPopup();
				var btnIndex = button.dataset.slideIndex;
				$(document).ajaxComplete(function () {
					app.slider.giftSlider.slideTo(btnIndex, 1000, false);
				});
			});
		};

		for (var i = 0; i < giftPopupBtn.length; i++) {
			_loop(i);
		}
		$(document).ajaxComplete(function () {
			var giftPopupHeroLink = document.querySelectorAll('.gift-list__link');

			var _loop2 = function _loop2(i) {
				var link = giftPopupHeroLink[i];
				link.addEventListener('click', function (event) {
					event.preventDefault();
					var dataSlide = link.dataset.slide;
					var slideIndex = document.getElementById('gift_' + dataSlide).dataset.slideIndex;
					console.log(slideIndex);
					app.slider.giftSlider.slideTo(slideIndex, 1000, false);
				});
			};

			for (var i = 0; i < giftPopupHeroLink.length; i++) {
				_loop2(i);
			}
		});
	}
};