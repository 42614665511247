'use strict';

/* eslint-disable prefer-const */
/* eslint-disable no-loop-func */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
app.votePopup = {
	init: function init() {
		var votePopupBtn = document.querySelectorAll('.rating__button');
		var showPopup = function showPopup() {
			$.fancybox.open({
				src: './vote-popup.html',
				type: 'ajax',
				opts: {
					smallBtn: false,
					toolbar: false,
					touch: false,
					padding: 10
				}
			});
		};

		var _loop = function _loop(i) {
			var button = votePopupBtn[i];
			button.addEventListener('click', function (event) {
				event.preventDefault();
				showPopup();
				var btnIndex = button.dataset.slideIndex;
				$(document).ajaxComplete(function () {
					app.slider.voteSlider.slideTo(btnIndex, 1000, false);
				});
			});
		};

		for (var i = 0; i < votePopupBtn.length; i++) {
			_loop(i);
		}

		$(document).ajaxComplete(function () {
			var votePopupHeroLink = document.querySelectorAll('.hero-list__link');

			var _loop2 = function _loop2(i) {
				var link = votePopupHeroLink[i];
				link.addEventListener('click', function (event) {
					event.preventDefault();
					var dataSlide = link.dataset.slide;
					var slideIndex = document.getElementById(dataSlide).dataset.slideIndex;
					app.slider.voteSlider.slideTo(slideIndex, 1000, false);
				});
			};

			for (var i = 0; i < votePopupHeroLink.length; i++) {
				_loop2(i);
			}
		});
	}
};