'use strict';

app.questions = {
	questionList: document.querySelectorAll('.questions__title'),
	init: function init() {
		if (this.questionList) {
			this.questionList.forEach(function (item) {
				item.addEventListener('click', function (evt) {
					evt.target.parentNode.classList.toggle('questions__item_open');
				});
			});
		}
	}
};